<template>
  <container @onBeforeCloseWindow="onBeforeCloseWindow">
    <div style="height: 100%;overflow: auto;display: flex;flex-direction: row;flex-wrap: wrap;align-content: center;"
         class="clearfix">
      <div style="flex: 0 0 25%;flex-shrink: 0;position: relative;min-width: 3.48rem;height: 3.52rem;float: left"
           v-for="(item, index) in videoList" :key="item.id" >
        <player-container :display="item.display" :name="item.name">
          <video :id="item.id" ref="video" playsinline width="100%" height="100%" autoplay :muted="index === 0"
                 v-show="item.display" @click="play(item)"></video>
        </player-container>
      </div>
    </div>

    <div slot="right" style="height: 100%;display: flex;flex-direction: column;">
      <item-header title="成员列表"/>
      <member-list :members="videoList"/>
      <item-header title="在线沟通"/>
      <text-chat-content-list :chat-list="textRoomChatList"/>
      <text-chat-send @send="sendTextMessage"/>
      <item-header title="文件上传">
        <div
          style="display: flex;flex-direction: row;width: 56px;height: 24px;font-size: 14px;background:#6EB2FF;border-radius:12px;color: #FFF;line-height: 24px;text-align: center;justify-content: center">
          <span>上传</span>
        </div>
      </item-header>
      <files/>
    </div>

    <div slot="bottom">
      <div style="display: flex;flex-direction: row">
        <operation @changeMediaSource="shareScreen" @toggleVideoMuted="toggleVideoMuted"
                   @toggleMicrophoneMuted="toggleMicrophoneMuted"/>
        <div style="flex: 1"></div>
        <sound-volume @changeBitrate="changeBitrate"/>
      </div>
    </div>
  </container>
</template>
<script>
import Container from './components/Container'
import Conferencing from '../../util/conferencing'
import ItemHeader from './components/ItemHeader'
import MemberList from './components/MemberList'
import TextChatContentList from './components/TextChatContentList'
import TextChatSend from './components/TextChatSend'
import Operation from './components/Operation'
import Files from './components/Files'
import SoundVolume from './components/SoundVolume'
import PlayerContainer from './components/PlayerContainer'
import { Loading } from 'element-ui'
import textRoom from './mixins/textRoom'
import videoRoom from './mixins/videoRoom'
import common from './mixins/common'
export default {

  name: 'video-conferencing-multiplayer',
  mixins: [common, textRoom, videoRoom],
  components: {
    Container,
    ItemHeader,
    MemberList,
    TextChatContentList,
    TextChatSend,
    Operation,
    Files,
    SoundVolume,
    PlayerContainer
  },

  data () {
    return {
      conferencing: null,
      roomId: Number(this.$route.query.room),
      feeds: [],
      videoList: [],
      textRoomUserMapper: {},
      textRoomChatList: [],
      transaction: (new Date()).getTime().toString(),
      mediaSource: 'video'
    }
  },

  created () {
    for (let i = 0; i < 8; i++) {
      this.videoList.push({
        title: '',
        id: this.$tools.uuid(),
        display: false,
        feed: null,
        name: ''
      })
    }
    const _this = this
    this.conferencing = new Conferencing(this.gateway, {
      success: function () {
        _this.useVideoRoom()
        _this.useTextRoom()
      }
    })
  },

  methods: {
    // 使用视频插件
    useVideoRoom () {
      const _this = this
      this.conferencing.attachVideoRoom({

        onSuccess: function (handler) {
          handler.send({
            message: { request: 'listparticipants', room: _this.roomId },
            success: function (res) {
              if (res.participants.length < 8) _this.joinVideoRoom(handler)
            }
          })
        },

        onJoined: function (handler, message) {
          _this.shareScreen('camera')
          message.publishers.forEach(item => {
            _this.createRemoteFeed(item.id, item.display, item.audio_codec, item.video_codec)
          })
        },

        onMessage: function (message) {
          if (message.videoroom === 'event' && message.publishers) {
            message.publishers.forEach(item => {
              _this.createRemoteFeed(item.id, item.display, item.audio_codec, item.video_codec)
            })
          }
        },

        onLeaving: function (handler, id) {
          console.log('onLeaving', id, _this.videoList)
          const remoteFeed = _this.videoList.find(item => item.uuid === id)
          if (remoteFeed === undefined) return
          remoteFeed.display = false
          remoteFeed.name = ''
          // // _this.videoList[remoteFeed.feed.rfindex].feed = null
          // remoteFeed.detach()
        },

        onLocalStream: function (stream) {
          _this.videoList[0].display = true
          _this.videoList[0].name = '自己'
          _this.videoList[0].uuid = _this.transaction
          window.Janus.attachMediaStream(_this.$refs.video[0], stream)
        }
      })
    },

    // 共享屏幕
    shareScreen (mediaSource) {
      const _this = this
      if (!mediaSource) mediaSource = 'video'
      this.mediaSource = mediaSource
      const media = { audioRecv: false, videoRecv: false, audioSend: true, videoSend: true, data: true }
      if (mediaSource === 'screen') media.video = mediaSource
      // 关闭推送
      _this.conferencing.handler.videoRoom.send({
        message: { request: 'unpublish' },
        success: function () {
          setTimeout(() => {
            _this.conferencing.handler.videoRoom.createOffer({
              media,
              simulcast: false,
              simulcast2: false,
              success: function (jsep) {
                var publish = { request: 'configure', audio: true, video: true }
                // publish.audiocodec = 'acodec'
                // publish.videocodec = 'vcodec'
                _this.conferencing.handler.videoRoom.send({ message: publish, jsep: jsep })
              },
              error: function (error) {
                window.Janus.error('WebRTC error:', error)
              }
            })
          }, 1000)
        }
      })
    },

    play (item) {
      const el = document.getElementById(item.id)
      el.play()
    }
  }
}
</script>
<style scoped>
.monitoring-container {
  height: 100%;
  overflow: hidden;
  position: relative;
  background: #000
}
</style>
